@font-face {
    font-family: 'TruenoSB';
    src: local('Trueno'), url(./fonts/truenosbd.otf) format('opentype');
  }

@font-face {
    font-family: 'TruenoR';
    src: local('Trueno'), url(./fonts/truenorg.otf) format('opentype');
}

.truenoTitle {
    font-family: 'TruenoSB';
}

.truenoLight {
    font-family: 'TruenoR';
}

.specialRed {
    color: #BA274A;
}

.glassy {
    background: rgba( 255, 255, 255, 0.55 );
    backdrop-filter: blur( 5.5px );
    -webkit-backdrop-filter: blur( 5.5px );
}

.tightenUp {
    padding: 1.2rem;
}

/* container */
.responsive-two-column-grid {
    display:block;
}

/* columns */
.responsive-two-column-grid > * {
    padding:1rem;
}

/* tablet breakpoint */
@media (min-width:768px) {
    .responsive-two-column-grid {
        display: grid;
        grid-template-columns: 1fr 10fr;
    }
}

.modeWrapper {
    display: none;
}
.dayCell {
    height: 50px !important;
}

.dayText.currentDay {
    padding: 0px !important;
    background-color: black !important;
    color: white !important;
}

.calendarHeaderButtons {
    color: black !important;
}

@keyframes pulsate {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.4;
    }
    100% {
        opacity: 1;
    }
}

.pulsate {
    animation: pulsate 2.5s infinite;
}

body {
    background-color: #FCFCFC;
}